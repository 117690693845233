$cdn: 'https://cdn.millions.co';
@import "styles/scss/_base";

$dot-diameter: 12px;
$dot-spacing: 8px;
$left-pos: -9999px;

$x1: -$left-pos;
$x2: $x1 + $dot-spacing;
$x3: $x2 + $dot-spacing;
$x4: $x3 + $dot-spacing;

$y1: -$dot-spacing;
$y2: 0;
$y3: $dot-spacing;

$gold-color-0: rgba($harvest-gold, 0);
$gold-color-1: rgba($harvest-gold, 1);
$black-color-0: rgba($black, 0);
$black-color-1: rgba($black, 1);

.fourDotsLoaderOverlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1200;
}

.fourDotsLoaderLoaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  border-radius: 0;
  background-color: $white;
  opacity: 0.9;

  @media screen and (min-width: $breakpoint_xs) {
    max-width: 175px;
    height: 175px;
    border-radius: 8px;
    opacity: 1;
  }
}

.fourDotsLoaderLoader {
  position: relative;
}

.dot {
  display: inline-block;
  position: relative;
  transform: translateX(-100%);
  left: $left-pos;

  @include dot($dot-diameter, $black);
}

.dot-1 {
  box-shadow: $x1 0 0 0 $harvest-gold;
  animation: dot-falling-1 1.2s infinite linear;
  animation-delay: 0.6s;
}

.dot-2 {
  box-shadow: $x2 0 0 0 $black;
  animation: dot-falling-2 1.2s infinite linear;
  animation-delay: 0.4s;
}

.dot-3 {
  box-shadow: $x3 0 0 0 $harvest-gold;
  animation: dot-falling-3 1.2s infinite linear;
  animation-delay: 0.2s;
}

.dot-4 {
  box-shadow: $x4 0 0 0 $black;
  animation: dot-falling-4 1.2s infinite linear;
  animation-delay: 0s;
}

@keyframes dot-falling-1 {
  0% {
    box-shadow: $x1 $y1 0 0 $gold-color-0;
  }

  25%,
  50%,
  75% {
    box-shadow: $x1 $y2 0 0 $gold-color-1;
  }

  100% {
    box-shadow: $x1 $y3 0 0 $gold-color-0;
  }
}

@keyframes dot-falling-2 {
  0% {
    box-shadow: $x2 $y1 0 0 $black-color-0;
  }

  25%,
  50%,
  75% {
    box-shadow: $x2 $y2 0 0 $black-color-1;
  }

  100% {
    box-shadow: $x2 $y3 0 0 $black-color-0;
  }
}

@keyframes dot-falling-3 {
  0% {
    box-shadow: $x3 $y1 0 0 $gold-color-0;
  }

  25%,
  50%,
  75% {
    box-shadow: $x3 $y2 0 0 $gold-color-1;
  }

  100% {
    box-shadow: $x3 $y3 0 0 $gold-color-0;
  }
}

@keyframes dot-falling-4 {
  0% {
    box-shadow: $x4 $y1 0 0 $black-color-0;
  }

  25%,
  50%,
  75% {
    box-shadow: $x4 $y2 0 0 $black-color-1;
  }

  100% {
    box-shadow: $x4 $y3 0 0 $black-color-0;
  }
}
