@mixin clearfix {
  zoom: 1;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin font_size($pixels) {
  font-size: $pixels;
  font-size: rem($pixels);
}

@mixin line_height($pixels) {
  line-height: $pixels;
  line-height: rem($pixels);
}

@mixin inline_overflow_ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin dot($width, $bg-color) {
  width: $width;
  height: $width;
  border-radius: $width / 2;
  background-color: $bg-color;
}
