$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.manageAddressBody {
  padding: 0 0 40px;
  display: grid;
  grid-gap: $spacing-2;
  grid-template-columns: repeat(0, [col-start] 1fr);

  @media screen and (min-width: $breakpoint_s) {
    grid-gap: 24px $spacing-2;
    grid-template-columns: repeat(2, [col-start] 1fr);
  }

  .manageAddressInput {
    &,
    &[class*="formControl"] {
      display: flex;
    }
  }

  .manageAddressHideInput {
    &,
    &[class*="formControl"] {
      display: none;
    }
  }
}

.manageAddressFooter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.manageAddressCta {
  white-space: nowrap;
}

.scrollDiv {
  display: none;
  height: 400px;
  width: 100px;

  @media screen and (max-width: 600px) {
    display: block;
  }
}
