$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  display: flex;
  justify-content: flex-end;
}

.advertiseButtons {
  @media screen and (max-width: $breakpoint_l) {
    display: none;
  }
}
