$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.phoneNumberContainer {
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: $spacing-1;

  &.disabledContainer {
    pointer-events: none;
    > div {
      background-color: $lights-dim;
    }
  }

  :global {
    .selected-flag {
      padding-right: 10px;

      &:hover {
        background-color: $background-dim;
      }
    }

    .selected-flag.open {
      background-color: $background-dim !important;
    }
  }
}

.phoneNumberLabel {
  color: $lights-high;
  display: block;

  @include text-body2Medium;
}

.phoneNumberInputContainer {
  height: 48px;

  :global {
    .flag-dropdown.open {
      background-color: $background-dim !important;
      border-radius: $radius-1;
    }
  }

  .phoneNumberButton {
    padding-right: 10px;
    text-align: left;
    background-color: $background-light;
    border: none;
    top: 5px;
    bottom: 5px;
    left: 5px;
    border-radius: $radius-1;

    div {
      div {
        transform: scale(1.3);
      }
    }

    &:hover {
      background-color: $background-dim;
    }
  }

  .phoneNumberInput {
    height: 100%;
    padding: 0px 60px;
    width: 100%;
    border: $border-1 solid $border-default;
    border-radius: $radius-1;
    background-color: $background-default;
    color: $lights-high;

    @include text-body1Regular16;

    &:focus {
      outline: none;
    }

    [class*="placeholder"] {
      @include text-body1Regular16;

      color: $lights-low;
    }
  }
}

.flagDropdown.open {
  min-height: 400px;
  background-color: $background-default;
  border: $border-1 solid $border-default;
  color: $lights-high;
  border-radius: $radius-1;
  z-index: 10;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  &.upwards {
    bottom: 100%;
  }
}

.flagDropdown.open {
  :global {
    .country-list {
      position: absolute;
      padding: 10px 0 10px;
      outline: none;
      list-style: none;
      background-color: $background-default;

      color: $lights-high;
      width: 300px;
      max-height: 500px;
    }

    .divider {
      border-bottom-color: $border-bright;
    }

    .country {
      &:hover {
        background-color: $background-dim;
      }
    }

    .country.highlight {
      background-color: $background-dim;
    }
  }
}
