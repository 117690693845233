$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.modal {
  margin: $header_height 0 0;

  @media screen and (min-width: $breakpoint_l) {
    margin: 0;
  }
}

div.overlay {
  padding: 0;
  background: rgba($background-default, 0.8);

  @media screen and (min-width: $breakpoint_l) {
    padding: 24px;
  }
}

div.modal {
  padding: 24px;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  border-radius: 0;
  background-color: $background-default;

  @media screen and (min-width: $breakpoint_l) {
    display: inline-block;
    margin: auto;
    min-height: unset;
    padding: 34px 16px;
    max-width: 585px;
    border: $border-1 solid $border-default;
    box-shadow: 0 -1px 38px rgba($accent-default, 0.04),
      0 -3px 33px rgba($accent-default, 0.01),
      0 -3px 34px rgba($accent-default, 0.03);
    margin-top: 60px;
  }

  &.size {
    &-small {
      max-width: unset;
      min-height: 100vh;

      @media screen and (min-width: 615px) {
        max-width: 452px;
        min-height: unset;
      }
    }

    &-medium {
      max-width: 585px;
    }

    &-large {
      max-width: 884px;
    }

    &-extra-large {
      max-width: 1116px;
    }
  }
}

.title {
  mix-blend-mode: difference;
  margin-top: 15px;

  &.withSubTitle {
    margin-bottom: 8px;
  }

  &.modal-size {
    &-extra-small {
      margin-bottom: 8px;
      padding-bottom: 12px;
      border-bottom: $border-1 solid $border-default;
    }

    &-small,
    &-medium,
    &-large,
    &-extra-large {
      margin-bottom: 40px;
    }
  }
}

.subTitle {
  mix-blend-mode: difference;

  margin-bottom: 32px;
}

.closeIcon {
  z-index: 2;
  fill: $lights-high;
}
