$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: flex;
  flex-direction: column;
  gap: $spacing-5;
  width: 100%;
}

.dateAndTimePickerWrapper {
  width: 100%;
  display: flex;
  margin-top: 20px;
}

.calendar {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.slotRoot {
  border: $border-1 solid $border-default;
  border-top-right-radius: $radius-2;
  border-bottom-right-radius: $radius-2;
  background-color: $background-default;
  overflow-y: scroll;
  padding: $spacing-2 $spacing-5;
  width: 100%;

  @include hide-scrollbar;
}

.noDateTime {
  color: $lights-high;
  padding: 10px;
}

.timeSlotsContainer {
  position: relative;
  max-height: 90%;
  margin-top: 5px;

  .loaderContainer {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.timeBtn {
  @extend %button-reset;

  @include text-body2Regular;

  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: $border-1 solid $border-default;
  border-radius: $radius-1;
  color: $lights-high;
  padding: $spacing-2 $spacing-6;
  margin: 10px 0;

  @media screen and (min-width: $breakpoint_l) {
    padding: 10px 50px;
  }

  &.selectedTimeSlot {
    border-color: $accent-default;
  }

  &:hover {
    background-color: $background-light;
    color: $lights-high;
    cursor: pointer;
  }
}
