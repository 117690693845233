$cdn: 'https://cdn.millions.co';
@import "styles/scss/_base";
@import "styles3/scss/base";

.collapseWrapper {
  @extend %card;
  padding: 12px;
}

.collapseHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
  }

  .collapseTitle {
    overflow: hidden;
    width: 100%;
  }

  .collapseToggle {
    margin: 4px;
    padding: 0;
    border: none;
    background: none;
    font-size: 0;
    transition: all 0.1s ease-in-out;

    &:hover {
      cursor: pointer;
    }

    &:focus,
    &:active {
      outline: none;
    }

    .isExpanded & {
      transform: rotate(180deg);
      transition: all 0.1s ease-in-out;
    }
  }
}

.icon {
  color: $lights-high;

  @include font_size(24px);
}

.collapseBody {
  display: none;
  padding: 20px 0 0;

  .isExpanded & {
    display: block;
  }
}
