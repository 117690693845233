$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.datePickerWrapper {
  width: 100%;
}

// used for date filter on streams
.isFilter {
  :global {
    .react-datepicker__navigation--next,
    .react-datepicker__navigation--previous {
      outline: none;
    }

    .react-datepicker__year-dropdown {
      width: 200px !important;
      display: grid;
      grid-template-columns: repeat(4, 1fr) !important;
      background-color: $background-default;
    }
  }
}

.datePickerCalendar {
  background-color: $background-default;
  border: $border-1 solid $border-default;
  border-radius: $radius-2;
  font-family: "GT America";
}

.datePickerCalendar,
.datePickerWrapper,
.datePickerPopper {
  // Rewrite default datepicker styles (only for this component)
  :global {
    .react-datepicker__navigation--next,
    .react-datepicker__navigation--previous {
      outline: none;
    }

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
      display: none;
    }

    .react-datepicker__current-month {
      display: none;
    }

    .react-datepicker__month {
      background-color: $background-default;
    }

    .react-datepicker__month-option--selected_month,
    .react-datepicker__month-option--selected_year {
      background-color: $accent-default;
    }

    .react-datepicker__year-option:hover,
    .react-datepicker__month-option:hover {
      background-color: $background-light;
      cursor: pointer;
    }

    .react-datepicker__month-option--selected_month,
    .react-datepicker__year-option--selected_year {
      background-color: $accent-default;
    }

    // hide check
    .react-datepicker__month-option--selected,
    .react-datepicker__year-option--selected {
      display: none;
    }

    .react-datepicker__header__dropdown {
      margin: 10px;
    }

    .react-datepicker__year-read-view,
    .react-datepicker__month-read-view {
      color: $lights-high;
      text-transform: uppercase;
    }

    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__year-read-view--down-arrow {
      transform: rotate(315deg);
    }

    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__year-read-view--down-arrow::before {
      border-top-color: $accent-default;
    }

    .react-datepicker__month-dropdown {
      background-color: $background-default;
      color: $lights-high;
    }

    .react-datepicker__year-option {
      padding: 2px;
    }

    .react-datepicker__month-option {
      padding: 5px;
      text-transform: uppercase;
    }

    .react-datepicker__year-dropdown {
      width: 300px;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      background-color: $background-default;
      color: $lights-high;
    }

    .react-datepicker__header {
      padding-top: 0;
    }

    .react-datepicker__header,
    .react-datepicker__time-container,
    .react-datepicker-popper[data-placement^="bottom"]
      .react-datepicker__triangle::before {
      border-color: $border-default;
      background-color: $background-default;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .react-datepicker__day,
    .react-datepicker__month-text,
    .react-datepicker__quarter-text,
    .react-datepicker__year-text,
    .react-datepicker-time__header {
      outline: none;
      color: $lights-high;
    }

    .react-datepicker__day {
      border-radius: 50%;

      &:hover {
        background-color: $background-light;
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--keyboard-selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: $accent-default;
      border-radius: 50%;
      color: $lights-high;
      outline: none;

      &:hover {
        background-color: $accent-default;
      }
    }

    .react-datepicker__day--disabled {
      color: $lights-low;
    }

    .react-datepicker__day-name {
      color: $lights-high;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      background-color: $accent-default;
      color: $lights-high;

      &:hover {
        background-color: $accent-default;
      }
    }

    .react-datepicker__time-list {
      background-color: $background-default;
      color: $lights-high;

      .react-datepicker__time-list-item:hover {
        background-color: $background-light !important;
      }

      .react-datepicker__time-list-item--selected:hover {
        background-color: $accent-default !important;
      }
    }

    // styles for screen size < 375 as for larger size these changes are not required
    @media screen and (max-width: 374px) {
      .react-datepicker__time-container {
        width: 50px;
      }

      .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box {
        width: 50px;
      }

      .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 65px;
      }
    }
  }
}
