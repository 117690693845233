$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.button {
  @extend %button-reset;

  // Variants
  &.primary {
    background-color: $accent-default;
    color: $background-dim;

    &:hover {
      background-color: $accent-bright;
    }

    &:active {
      background-color: $accent-dim;
      color: $lights-high;
    }

    &:disabled {
      background-color: rgba(178, 129, 45, 0.2);
      color: $accent-dim;
    }

    &.outline {
      background-color: transparent;
      border: $border-1 solid $accent-default;
      color: $accent-default;

      &:hover {
        background-color: transparent;
        border-color: $accent-bright;
        color: $accent-bright;
      }

      &:active {
        background-color: transparent;
        border-color: $accent-dim;
        color: $lights-high;
      }

      &:disabled {
        background-color: transparent;
        border-color: #ab833e80;
        color: #ab833e80;
      }
    }

    &.text {
      background-color: transparent;
      color: $accent-default;

      &:hover {
        background-color: transparent;
        color: $accent-bright;
      }

      &:active {
        background-color: transparent;
        color: $accent-bright;
      }

      &:disabled {
        background-color: transparent;
        color: #ab833e80;
      }
    }
  }

  &.secondary {
    background-color: $background-light;
    color: $lights-high;

    &:hover {
      background-color: $lights-high;
      color: $background-dim;
    }

    &:active {
      background-color: $lights-high;
      color: $background-dim;
    }

    &:disabled {
      background-color: $background-light;
      color: $lights-dim;
    }

    &.outline {
      background-color: transparent;
      border: $border-1 solid $border-default;
      color: $lights-high;

      &:hover {
        background-color: transparent;
        border-color: $lights-low;
        color: $lights-high;
      }

      &:active {
        background-color: transparent;
        border-color: $lights-high;
        color: $lights-high;
      }

      &:disabled {
        background-color: transparent;
        border-color: $lights-dim;
        color: $lights-dim;
      }
    }

    &.text {
      background-color: transparent;
      color: $lights-high;

      &:hover {
        background-color: transparent;
        color: $lights-medium;
      }

      &:active {
        background-color: transparent;
        color: $lights-medium;
      }

      &:disabled {
        background-color: transparent;
        color: $lights-dim;
      }
    }
  }

  &.error {
    background-color: $error-default;
    color: $lights-high;

    &:hover {
      background-color: $error-dim;
    }

    &:active {
      background-color: $error-dim;
      color: $lights-high;
    }

    &:disabled {
      background-color: $error-tint;
      color: $error-dim;
    }

    &.outline {
      background-color: transparent;
      border: $border-1 solid $error-default;
      color: $error-default;

      &:hover {
        background-color: transparent;
        border-color: $error-dim;
        color: $error-dim;
      }

      &:active {
        background-color: transparent;
        border-color: $error-dim;
        color: $error-dim;
      }

      &:disabled {
        background-color: transparent;
        border-color: #f43f3680;
        color: #f43f3680;
      }
    }

    &.text {
      background-color: transparent;
      color: $error-default;

      &:hover {
        background-color: transparent;
        color: $error-dim;
      }

      &:active {
        background-color: transparent;
        color: $error-dim;
      }

      &:disabled {
        background-color: transparent;
        color: #f43f3680;
      }
    }
  }

  &.success {
    background-color: $success-default;
    color: $lights-high;

    &:hover {
      background-color: $success-dim;
    }

    &:active {
      background-color: $success-dim;
      color: $lights-high;
    }

    &:disabled {
      background-color: $success-tint;
      color: $success-dim;
    }

    &.outline {
      background-color: transparent;
      border: $border-1 solid $success-default;
      color: $success-default;

      &:hover {
        background-color: transparent;
        border-color: $success-dim;
        color: $success-dim;
      }

      &:active {
        background-color: transparent;
        border-color: $success-dim;
        color: $success-dim;
      }

      &:disabled {
        background-color: transparent;
        border-color: #06c27080;
        color: #06c27080;
      }
    }

    &.text {
      background-color: transparent;
      color: $success-default;

      &:hover {
        background-color: transparent;
        color: $success-dim;
      }

      &:active {
        background-color: transparent;
        color: $success-dim;
      }

      &:disabled {
        background-color: transparent;
        color: #06c27080;
      }
    }
  }

  // Sizes
  &.large {
    @include text-buttonL;

    padding: $spacing-3 $spacing-4;
    border-radius: $radius-1;
  }

  &.medium {
    @include text-buttonL;

    padding: $spacing-2 $spacing-3;
    border-radius: $radius-1;
  }

  &.small {
    @include text-buttonM;

    padding: $spacing-1 $spacing-2;
    border-radius: $radius-0;
  }
}

.buttonContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: max-content;
  gap: $spacing-1;
}
