$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  padding: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacing-4;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
  background-color: $background-default;

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      opacity: 0.8;
      cursor: not-allowed;
      user-select: none;
      box-shadow: none;
    }
  }

  &.error {
    border: 2.5px dashed $error-default;
  }

  @extend %button-reset;
}

.icon {
  color: $accent-default;
}
