$cdn: 'https://cdn.millions.co';
@import "styles/scss/_base";
@import "styles3/scss/_base";

.tab {
  padding: 12px 8px;
  min-width: 78px;
  border: none;
  background: none;
  font-weight: $semi_bold_font_weight;
  text-align: center;
  letter-spacing: -0.2px;
  color: $lights-dim;
  flex-shrink: 0;

  @include font_size(20px);
  @include line_height(26px);

  &:hover {
    cursor: pointer;
    background-color: $lights-dim;
  }

  &:focus {
    outline: none;
  }

  i {
    color: $lights-low;

    @include font_size(18px);
  }
}

.underline {
  padding: 12px 18px 11px;
  border-bottom: $border-1 solid $border-default;
  background: transparent;

  &:hover,
  &:focus,
  &:active,
  &.selected {
    border-bottom: $border-1 solid $accent-default;
    background: transparent;
    color: $lights-high;
  }

  &:active,
  &.selected {
    color: $lights-high;
    transition: all .3s ease-in-out;
  }
}

.pills {
  border-radius: $radius-4;

  &.selected {
    background-color: $accent-default;
  }
}

.segmented {
  padding: 8px;
  background: $background-dim;

  &.selected {
    border-radius: 4px;
    background-color: $accent-default;
    color: $lights-high;
  }
}
