$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  height: 250px;

  @media screen and (max-width: 400px) {
    height: calc(50vw + 50px);
  }
}

.imageFrame {
  width: 180px;
  height: 180px;
  border: $border-2 solid $border-default;
  border-radius: $radius-2;
  overflow: hidden;

  &:hover .removeButton {
    visibility: visible;
  }

  @media screen and (max-width: $breakpoint_l) {
    max-width: 180px;
    max-height: calc(50vw - 21px);
    width: calc(50vw - 21px);
  }
}

.image {
  width: 180px;
  height: 180px;
  aspect-ratio: 1 / 1;

  @media screen and (max-width: $breakpoint_l) {
    max-width: 180px;
    max-height: 180px;
    width: calc(50vw - 21px);
    height: calc(50vw - 21px);
    aspect-ratio: 1 / 1;
  }
}

.removeButton {
  @extend %button-reset;

  position: relative;
  top: -182px;
  left: 136px;
  cursor: pointer;
  visibility: hidden;
  color: $error-default;
  background-color: $lights-medium;
  width: 38px;
  height: 38px;
  border-radius: 50%;

  @media screen and (max-width: 400px) {
    top: calc(-50vw + 20px);
    left: calc(50vw - 65px);
  }
}

.isMainText {
  padding: 5px 0;
  width: 70px;
  text-transform: uppercase;
  text-align: center;
  background-color: $accent-default;
  color: $lights-high;
  position: relative;
  top: -224.5px;
  left: 0;

  @include font_size(8px);

  @media screen and (max-width: 400px) {
    top: calc(-50vw - 24px);
  }
}

.setMainButtonContainer {
  max-width: 180px;
  text-align: center;
  position: relative;
  top: 10px;
  height: 50px;

  @media screen and (max-width: $breakpoint_l) {
    max-width: 180px;
    width: calc(50vw - 21px);
  }
}

.isMainButton {
  background-color: $accent-default !important;
  cursor: default !important;
  border: none !important;
}
