$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.uploadImageWrapper {
  width: 100%;

  .isDragging {
    color: $error-default;
  }
}

.displayBlock {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  @media screen and (max-width: $breakpoint_l) {
    max-width: 100%;
  }
}

.previewImageContainer {
  visibility: none;
  height: 0px;
  position: relative;
  border-radius: $radius-2;
  overflow: hidden;
}

.previewVisible {
  visibility: visible;
  max-width: 370px;
  max-height: 370px;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  margin-bottom: 30px;
}

.previewImage {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border: $border-2 solid $border-default;
  border-radius: $radius-2;
  overflow: hidden;
}

.isMainPreviewText {
  padding: 5px 0;
  width: 70px;
  text-transform: uppercase;
  text-align: center;
  background-color: $accent-default;
  color: $lights-high;
  position: absolute;
  top: 0;
  left: 0;

  @include font_size(8px);

  @media screen and (max-width: $breakpoint_l) {
    top: 87px;
  }
}

.imagesBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
