$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  .button {
    padding: 4px;
  }
}

.isActive {
  background-color: $accent-default;
  border-color: $accent-default;
}

.icon {
  svg {
    color: $accent-default;
  }

  &.isActive {
    svg {
      color: white;
    }
  }
}
