$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  display: inline-block;
  padding: 4px;

  .button {
    padding: 4px;
  }
}

.tooltipRoot {
  background-color: $white;
  color: $black;
  border: 1px solid $light-gray;
  opacity: 1;
  z-index: 1;
}

.tooltipArrow {
  border-bottom: 1px solid $light-gray;
  border-right: 1px solid $light-gray;
  opacity: 1;
  z-index: 1;
}

.rightTooltipArrow {
  border-right: none;
  border-left: 1px solid $light-gray;
}

.content {
  display: flex;
  flex-direction: column;

  .inputWrapper {
    display: inline-flex;
    padding-bottom: 8px;

    .input {
      padding-right: 8px;

      & input {
        padding: 6px;
      }

      &.isInvalid {
        input {
          border-color: #ff4d4f;
        }
      }
    }

    .button {
      padding: 4px;
    }
  }
}

.title {
  padding: 6px 0 10px;

  @include text-base-regular;
}

.icon {
  color: #1890ff;

  &.isActive {
    color: white;
  }
}

.checkbox {
  padding-bottom: 8px;
}

.error {
  color: #ff4d4f;
}
