$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.form {
  display: flex;
  flex-direction: column;
  gap: $spacing-7;
  padding-bottom: $spacing-5;
}

.section {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
}

.priceQuantity {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr;
  gap: $spacing-5;
}

.finalPrice {
  display: flex;
  flex-direction: column;
  gap: $spacing-1;

  & .finalPriceLabel {
    display: inline-flex;
    align-items: center;
    gap: $spacing-1;
  }
}
