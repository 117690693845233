$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: $spacing-8;
  padding: $spacing-6;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-4;

  .title {
    @include text-h5;

    @media screen and (min-width: $breakpoint_l) {
      @include text-h3;
    }
  }

  .subtitle {
    @include text-body2Regular;

    @media screen and (min-width: $breakpoint_l) {
      @include text-body1Regular16;
    }
  }
}

.buttonsContainer {
  display: flex;
  gap: $spacing-4;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_l) {
    flex-direction: row;
  }
}

.button {
  @extend %button-reset;

  max-width: 354px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-2;
  border: $border-1 solid $border-default;
  border-radius: $radius-2;
  padding: $spacing-4;

  img {
    width: 155px;
    height: 155px;
  }

  &.finished {
    cursor: not-allowed;
    border-color: $accent-default;
  }
}
