$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

$modal_mobile_header_height: 52px;
$modal_desktop_header_height: 80px;

div.root,
div.overlay {
  top: $header_height;
}

div.modalContainer {
  @include hide-scrollbar;
}

div.modal {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  overflow: hidden;
  background: $background-dim;
}

.header {
  position: fixed;
  top: $header_height;
  width: 100%;
  height: $modal_mobile_header_height;
  display: flex;
  flex-direction: column;
  background: $background-dim;
  z-index: 1;

  @media screen and (min-width: $breakpoint_l) {
    height: $modal_desktop_header_height;
  }
}

.headerBody {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  padding: $spacing-3 $spacing-4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  @media screen and (min-width: $breakpoint_l) {
    padding: $spacing-4 $spacing-6;
  }

  &::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 100vw;
    bottom: 0;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background: $background-light;
  }
}

.closeButton {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  @include flexCenter;
}

.closeIcon {
  font-size: 24px;
  color: $lights-high;
}

.body {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: $spacing-3 $spacing-4;
  margin: $header_height 0 0;
  width: 100%;
  min-height: calc(100vh - #{$header_height} - #{$modal_mobile_header_height});
  height: 100%;

  @media screen and (min-width: $breakpoint_l) {
    padding: $spacing-4 $spacing-6;

    min-height: calc(
      100vh - #{$header_height} - #{$modal_desktop_header_height}
    );
  }

  &.withFooter {
    margin-bottom: 80px;
  }
}

.footer {
  position: fixed;
  z-index: 2;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: $spacing-3;
  background: $background-default;
  border-top: $border-1 solid $border-default;
  padding: $spacing-3 $spacing-4;

  @media screen and (min-width: $breakpoint_l) {
    padding: $spacing-4 $spacing-6;
  }

  .prevButton {
    justify-self: stretch;

    @media screen and (min-width: $breakpoint_l) {
      justify-self: flex-start;
    }
  }

  .prevButtonPlaceholder {
    visibility: hidden;
  }

  .submitButton {
    justify-self: stretch;

    @media screen and (min-width: $breakpoint_l) {
      justify-self: flex-end;
    }
  }

  .buttonsContainer {
    display: flex;
    gap: $spacing-2;
    justify-content: flex-end;
  }

  .stepContainer {
    display: none;

    @media screen and (min-width: $breakpoint_l) {
      display: flex;
      align-items: center;
      justify-self: center;
    }
  }
}

.scrollRef {
  visibility: hidden;
}
