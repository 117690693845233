$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.modalRoot {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  justify-content: center;
  align-items: center;
}

.image {
  width: 96px;
}

.texContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  text-align: center;
  max-width: 390px;
}

.copyWrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
  max-width: 480px;
  margin: auto;
  background-color: $background-default;

  .copyTitle {
    color: $lights-medium;
    font-weight: 500;
    padding-bottom: 5px;

    @include font_size(14px);
  }

  .buttonWrapper {
    display: flex;
    flex-direction: row;

    .copyButton {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: $background-default;
      border: $border-1 solid $border-default;
      border-radius: $radius-1;
      cursor: pointer;

      .linkWrapper {
        padding: 9px 16px;
        white-space: nowrap;
        max-width: 310px;
        overflow: hidden;
        text-overflow: ellipsis;

        .linkPreview {
          color: $lights-high;

          @include text-body1Regular16;
        }
      }

      .iconWrapper {
        display: flex;
        border-left: 1px solid $border-default;
        padding-left: 10px;
        align-items: center;
        align-self: center;

        .copyButtonIcon {
          color: $accent-default;
          padding-right: 5px;
          margin: 0 5px;
        }

        .buttonText {
          width: 55px;
          color: $accent-default;
          margin-right: 10px;

          @include font_size(12px);
        }
      }
    }
  }
}
