$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

$variants: (
  primary: (
    background: $background-light,
    color: $lights-high,
  ),
  secondary: (
    background: $lights-high,
    color: $background-dim,
  ),
  disabled: (
    background: $background-default,
    color: $lights-dim,
  ),
  error: (
    background: $error-bright,
    color: $error-default,
  ),
  live: (
    background: $error-default,
    color: $lights-high,
  ),
  accent: (
    background: $accent-dim,
    color: $lights-high,
  ),
  text: (
    background: transparent,
    color: $accent-default,
  ),
  inStock: (
    background: $success-dim,
    color: $lights-high,
  ),
  outOfStock: (
    background: $error-dim,
    color: $lights-high,
  ),
);

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-0;
  border-radius: $radius-0;

  .icon {
    margin-right: $spacing-0;
  }

  // Sizes
  &.small {
    @include text-smallLabelBold;
    padding: $spacing-1; // Figma has extra lr padding for small, its not $spacing-0 so doing $spacing-1
  }

  // Big from figma
  &.medium {
    @include text-largeLabelBold;
    padding: $spacing-1;
  }

  // XL from figma
  &.large {
    @include text-xLargeLabelBold;
    height: 30px;
    padding: $spacing-2;
  }

  // Variants
  @each $variant, $props in $variants {
    &.#{$variant} {
      background-color: map-get($props, background);
      color: map-get($props, color);

      .icon {
        color: map-get($props, color);
      }
    }
  }
}
