$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  display: flex;
  flex-direction: column;
}

.millionsMentionsSection {
  display: flex;
  flex-direction: column;
  gap: $spacing-2;
  padding-bottom: $spacing-4;
}

.addNewButton {
  padding: 0 !important;
}
