$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.imagesList {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.addNewButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-1;
  min-width: 360px;
  padding: 0;
  height: 360px;
  border: $border-1 dashed $border-default;
  border-radius: $radius-2;
  background-color: $background-default;

  @media screen and (max-width: $breakpoint_l) {
    min-width: auto;
    max-width: 360px;
  }

  &.withImage {
    min-width: 180px;
    max-width: 180px;
    height: 180px;
  }
}

.iconContainer {
  width: 100%;
  color: $accent-default;
}

.addIcon {
  width: 36px;
  height: 36px;
}

.addText {
  width: 100%;
}
