$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-6;
}

.micImage {
  width: 156px;
  height: 156px;
}

.hostCard {
  background-color: $background-default;
  border-radius: $radius-2;
  padding: $spacing-5;
  display: flex;
  gap: $spacing-6;

  .avatar {
    width: 180px;
    border-radius: $radius-1;
  }
}

.basicInfoWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-3;
}

.hostTitle {
  margin-bottom: $spacing-3;
}