$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.leftContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-7;
}

.form {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
}

.dateTimezoneContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  @media screen and (min-width: $breakpoint_s) {
    flex-direction: row;
  }
}

.priceFieldContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $spacing-4;

  @media screen and (min-width: $breakpoint_m) {
    flex-direction: row;
    align-items: center;
  }
}

.freePriceContainer {
  min-width: 200px;
  display: flex;
  align-items: center;
  gap: $spacing-2;
}

.testContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-2;
}

.mentionsSection {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
}

.imageContainer {
  padding-top: $spacing-4;
}

.previewImageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.previewImage {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 4px;
  background-color: $background-default;

  @media screen and (min-width: $breakpoint_m) {
    width: 100%;
  }
}

.removePreviewButton {
  position: absolute;
  right: 10px;
  top: 10px;
}

.dropzone {
  background-color: $background-default;
  border: $border-1 dashed $border-default;
  border-radius: $radius-2;
  max-width: 360px;
}

.streamSettingsFooter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing-4;
  padding-top: $spacing-4;
}

.lowerZIndexContainer {
  z-index: 0;
}
