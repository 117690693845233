$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  gap: $spacing-5;
  padding: $spacing-6 0 0;
}

.image {
  max-width: 246px;
}

.link {
  @include text-body1Medium16;

  color: $accent-default;
}
