$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

$radioButton-size: 18px;
$innerCircle-size: 8px;

.radioButtonContainer {
  margin: 0;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: $breakpoint_l) {
    width: auto;
  }
}

.radioButtonLabel {
  position: relative;
  display: inline-block; // ensures the label's box is sized to its content
  padding-left: $radioButton-size + 10px;
  color: $lights-high;
  cursor: pointer;
  @include text-body1Regular16; // sets font-size and line-height

  &.bold {
    @include text-body1Medium16;
  }

  // Outer circle: Center vertically in the label
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: $radioButton-size;
    height: $radioButton-size;
    border-radius: 50%;
    border: $border-3 solid $lights-high;
    box-sizing: border-box;
    background: transparent;
    transition: $transition-basic;
  }

  // Inner circle: Center within the outer circle
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: ($radioButton-size / 2);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: $lights-high;
    opacity: 0;
    transition: $transition-basic;
  }

  &:hover,
  &:active {
    &:after {
      width: $innerCircle-size;
      height: $innerCircle-size;
      opacity: 1;
    }
  }
}

.radioButtonInput {
  position: absolute;
  left: -9999px;

  &:hover,
  &:active {
    + label {
      &:after {
        width: $innerCircle-size;
        height: $innerCircle-size;
        opacity: 1;
      }
    }
  }

  &:checked {
    + label {
      &:after {
        width: $innerCircle-size;
        height: $innerCircle-size;
        opacity: 1;
        transition: $transition-basic;
      }
    }
  }

  &:disabled {
    + label {
      user-select: none;
      pointer-events: none;
      cursor: not-allowed;

      &:before,
      &:after {
        border-color: $border-bright;
      }
    }

    &:checked {
      + label {
        &:before,
        &:after {
          border-color: $border-bright;
        }
      }
    }
  }
}
