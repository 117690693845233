$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  outline: none;
  
  // Sizes
  @each $name, $value in $cardSizes {
    &.#{$name} {
      max-width: 100%;
      width: $value;
    }
  }
}

.imageContainer {
  position: relative;
  margin-bottom: $spacing-1;

  // Sizes
  @each $name, $value in $cardSizes {
    &.#{$name} {
      max-height: $value;
    }
  }
}

.labelWrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: $spacing-2;
  z-index: 1;
  padding: $spacing-2;

  &.productCard {
    width: 100%;
  }

  &.bottom {
    top: auto;
    bottom: 0;
  }

  // Sizes
  @each $name, $value in $cardSizes {
    &.#{$name} {
      // Example: using a different spacing variable if needed
      padding: if($name == small, $spacing-2, $spacing-3);
    }
  }
}

.imageWrapper {
  aspect-ratio: 1;
  overflow: hidden;
  background-color: $background-default;
  border: $border-1 solid $border-default;
  border-radius: $radius-2;
  flex-shrink: 0;
  max-width: 100%;

  &.articleCard {
    aspect-ratio: 3/2;
  }
}

.playIconOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: $lights-high;
}
