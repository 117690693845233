$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

$checkbox-size: 18px;

.checkBoxContainer {
  margin: 0;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.checkBoxLabel {
  position: relative;
  padding-left: $checkbox-size + 10px;
  color: $lights-high;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  @include text-body2Regular;

  &.alignTop {
    align-items: flex-start;
  }

  &.bold {
    @include text-body2Medium;
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: $checkbox-size;
    height: $checkbox-size;
    box-sizing: border-box;
  }

  &:before {
    content: "";
    border: $border-2 solid $lights-high;
    border-radius: $radius-0;
    background: transparent;
    &:checked {
      background: $accent-bright;
    }
  }

  &:after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M19.7397%206.26035C20.0868%206.60748%2020.0868%207.1703%2019.7397%207.51743L9.96187%2017.2952C9.61474%2017.6423%209.05193%2017.6423%208.70479%2017.2952L4.26035%2012.8508C3.91322%2012.5036%203.91322%2011.9408%204.26035%2011.5937C4.60748%2011.2466%205.1703%2011.2466%205.51743%2011.5937L9.33333%2015.4096L18.4826%206.26035C18.8297%205.91322%2019.3925%205.91322%2019.7397%206.26035Z%22%20fill%3D%22%230A0A0C%22%20style%3D%22fill%3A%230A0A0C%3Bfill-opacity%3A1%3B%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: $border-2 solid $accent-bright;
    background-color: $accent-bright;
    border-radius: $radius-0;
  }
}

.checkBoxInput {
  position: absolute;
  left: -9999px;

  + label {
    &:after {
      opacity: 0;
      transform: scale(0);
    }
  }

  &:hover,
  &:active {
    + label {
      &:before,
      &:after {
        transform: scale(1);
        opacity: 0.25;
      }
    }
  }

  &:checked {
    + label {
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }

    &:hover {
      + label {
        &:before,
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &:disabled {
    + label {
      user-select: none;
      pointer-events: none;
      cursor: not-allowed;

      &:before,
      &:after {
        border-color: $lights-low;
        background-color: $lights-low;
      }
    }

    &:checked {
      + label {
        &:before,
        &:after {
          border-color: $accent-dim;
          background-color: $accent-dim;
          color: $lights-low;
        }
      }
    }
  }
}
