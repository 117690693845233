$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.link {
  @extend %button;
  display: inline-block;
}

.notAllowed {
  cursor: not-allowed !important;
  opacity: 0.8;
}
