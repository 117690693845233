$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-3;
  padding: $spacing-3 $spacing-4;
  background-color: $background-default;
  border-bottom: $border-0 solid $border-default;
  color: $lights-high;

  &.firstRow {
    border-top-left-radius: $radius-2;
    border-top-right-radius: $radius-2;
  }

  &.lastRow {
    border-bottom-left-radius: $radius-2;
    border-bottom-right-radius: $radius-2;
    border-bottom: none;
    margin-bottom: $spacing-5;
  }
}

.content {
  display: flex;
  align-items: center;
  gap: $spacing-3;
}

.removeButton {
  @extend %button-reset;

  color: $lights-high;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: $spacing-1;

  &:hover {
    background-color: $background-light;
  }
}
