$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";


.left {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.right {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.toggleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $background-default;
  height: 48px;
  border-radius: $radius-1;
  margin-bottom: $spacing-2;
  padding: 0 $spacing-4;
}

.toggle {
  position: relative;
  width: 48px;
  height: 24px;
  background-color: #e0e0e0;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggleActive {
  background-color: #ffc107;
}

.toggleHandle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggleHandleActive {
  transform: translateX(24px);
}

.helperText {
  margin-top: 8px;
}

.detailedForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.categorySection {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.currencyInput {
  margin-top: 8px;
}

.form
.reviewSection, 
.finalizeSection,
.advancedSettings,
.profileForm {
  padding: $spacing-6;
  display: flex;
  flex-direction: column;
  gap: $spacing-5;
  width: 100%;
}

.preferenceItem {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #e0e0e0;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
}

.navigationButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.categoriesPadding {
  padding-bottom: $spacing-2;
}

.switchClass {
  width: 100%;
  border-radius: $radius-2;
  flex: display;
  background-color: $background-default !important;
  color: $background-default;
}

.settingHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionTitle {
  font-weight: 600;
  margin: 16px 0 8px;
  color: #757575;
}

.categoryItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}

.categoryNote {
  margin-top: 16px;
  font-size: 12px;
}

.formField {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.selectWrapper {
  position: relative;
}

.select {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  appearance: none;
  background-color: transparent;
}

.selectIcon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.dateInput,
.locationInput {
  width: 100%;
}

.dateInput::placeholder {
  text-transform: uppercase;
}

.leagueBadges {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.leagueBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
}

.settingItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.categoryItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $border-default;
  background-color: $background-default;
  padding: $spacing-2 $spacing-4;
  width: 100%;
  height: 100%;
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.categoryIcon {
  width: 20px;
  height: 20px;
  opacity: 0.8;
}