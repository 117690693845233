$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";
@import "styles3/scss/base";

.root {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  padding: 0 $spacing-5;
  color: $lights-high;
  position: relative;
}

.modal {
  top: $header-height;
}

.links {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  padding: $spacing-4;
  background-color: $background-dim;
  border-radius: $radius-2;
}
