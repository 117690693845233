$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";
@import "styles3/scss/base";

.form {
  width: 100%;
}

.imageCropper {
  width: 100%;
  height: 220px;

  @media screen and (min-width: $breakpoint_m) {
    width: 486px;
  }
}

.previewImageWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

.previewImage {
  width: 100%;
  height: 220px;
  object-fit: contain;
  border-radius: 4px;
  background-color: $lights-high;

  @media screen and (min-width: $breakpoint_m) {
    width: 486px;
  }
}

.removePreviewButton {
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  border: 1px solid $alto;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05), 0 1px 18px rgba(0, 0, 0, 0.03),
    0 6px 10px rgba(0, 0, 0, 0.04);
  border-radius: 28px;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    border: 1px solid $black;
  }
}

.removePreviewIcon {
  margin-top: -0.6px;
  color: $accent-bright;
}

.input,
.textarea,
.datePicker,
.timeZonePicker {
  margin-bottom: 16px;
}

.richTextWrapper {
  border-radius: 0;
  color: $lights-high;

  font-size: font_size(16);
  font-weight: $medium_font_weight;
}

.switch {
  &,
  &[class*="switchWrapper"] {
    margin: 0 0 16px;
  }
}

.dropzone {
  margin: 8px 0;
  padding: 19px 40px;
  background: $background-default;
  border: 1px dashed $alto-light;
  cursor: pointer;
  outline: none;

  &.error {
    border-color: $maximum-red;
  }

  &:hover,
  &:focus {
    border: 1px dashed $black;
  }
}

.dragArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dragAreaIcon {
  margin-right: 12px;
  color: #969696;
}

.dragAreaText {
  display: flex;
  font-weight: $medium_font_weight;
  letter-spacing: -0.1px;

  @include font_size(12px);
  @include line_height(18px);
}

.dragLabel {
  display: none;
  margin-right: 4px;
  color: $dusty-grey;

  @media screen and (min-width: $breakpoint_ml) {
    display: block;
  }
}

.attachLabel {
  color: $harvest-gold;
}

.imageRestrictionsText {
  margin-bottom: 26px;
  letter-spacing: -0.2px;
  color: $dove-gray;
  text-align: center;

  @include font_size(12px);
  @include line_height(18px);
}

.inputWithSwitchWrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;

  @media screen and (max-width: $breakpoint_xs) {
    align-items: flex-start;
  }

  .input {
    width: auto;
  }

  .switch {
    margin: 0 24px 16px 16px;
    width: auto;

    @media screen and (max-width: $breakpoint_xs) {
      margin-top: 10px;
    }

    label {
      white-space: nowrap;

      @media screen and (max-width: $breakpoint_xs) {
        padding-left: 4px;
        padding-top: 38px;
      }
    }
  }
}

.testSwitchWrapper {
  display: flex;
  align-items: center;
  height: 100%;

  .switch {
    width: auto;
    margin-right: 20px;
  }

  .testInfo {
    margin-bottom: 16px;
  }
}

.submitButton {
  width: 100%;
}
