$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $spacing-1;
}

.label {
  @include text-body2Medium;
}

.textarea {
  margin: 0;
  padding: $spacing-3;
  width: 100%;
  background-color: $background-default;
  border: $border-1 solid $border-default;
  border-radius: $radius-1;
  outline: none;
  font-family: inherit;
  appearance: none;
  color: $lights-high;
  resize: vertical;

  @include text-body1Regular16;

  &::placeholder {
    color: $lights-low;
  }

  &.error {
    border-color: $error-default;
  }
}
