$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  max-width: 300px;
  overflow: hidden;
}

.tooltipRoot {
  background-color: $white;
  color: $black;
  border: 1px solid $light-gray;
  opacity: 1;
}

.tooltipArrow {
  border-bottom: 1px solid $light-gray;
  border-right: 1px solid $light-gray;
  opacity: 1;
  z-index: 1;
}

.cta {
  padding: 0;
  color: #1890ff;
  background: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-size: inherit;

  &.isActive {
    text-decoration: underline;
  }
}

.content {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  user-select: none;

  .linkButton {
    background: none;
    border: none;
    padding: 0;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
  }

  .btnMargin {
    margin-right: 8px;
  }

  .button {
    padding: 4px;
  }
}
