$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.form {
  display: flex;
  flex-direction: column;
  gap: $spacing-7;
}

.section {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
}

.radioGroup {
  display: flex;
  gap: $spacing-3;
}

.twoItemsInRow {
  display: flex;
  flex-direction: row;
  gap: $spacing-3;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.addressList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $spacing-4;
}

.addressButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: $spacing-3;
}

.finalPrice {
  display: flex;
  flex-direction: column;
  gap: $spacing-1;

  & .finalPriceLabel {
    display: inline-flex;
    align-items: center;
    gap: $spacing-1;
  }
}
