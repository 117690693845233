$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: $border-2 dashed $border-default;
  border-radius: 100%;
  overflow: hidden;

  &.hasImage {
    border-style: solid;
  }
}

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacing-4;
  width: 352px;
  height: 352px;
  cursor: pointer;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-1;
}

.image {
  width: 352px;
  height: 352px;
  object-fit: contain;
}

.imageContainer {
  position: relative;
}

.editButton {
  @extend %button-reset;

  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-color: $lights-high;
  color: $background-dim;
  position: absolute;
  bottom: $spacing-4;
  left: 50%;
  transform: translateX(-50%);
}
