$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  color: $lights-high;
}

.dateWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: $breakpoint_l) {
    gap: 16px;
  }
}

.dayWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: $breakpoint_l) {
    flex-direction: row;
  }

  .dayPickerWrapper {
    width: 100%;
    height: 60px;

    @media screen and (min-width: $breakpoint_l) {
      width: 250px;
    }

    .calendarIcon {
      width: 20px;
      line-height: 0;
      height: 20px;
      position: relative;
      top: -42px;
      color: $lights-low;
      left: calc(100% - 40px);

      @media screen and (min-width: $breakpoint_l) {
        top: -39px;
        left: 210px;
      }
    }
  }
}

.timePickerWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.timeRangeWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  @media screen and (min-width: $breakpoint_l) {
    flex-direction: row;
  }

  .dayName {
    width: 120px;
    color: $lights-high;
  }

  .timeRange {
    display: flex;
    flex-direction: row;
    width: 350px;

    .timeInput {
      flex-grow: 0;
      align-self: start;
    }

    .timeSeparator {
      padding: 20px 10px;
      color: $lights-high;
      align-self: start;
    }
  }
}

.icon {
  cursor: pointer;
  background-color: $background-default;
  color: $accent-default;
  height: 22px;
  width: 22px;
}
