$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.noRedirect {
  &:hover {
    cursor: pointer;
    & .playIcon,
    & .pauseIcon {
      opacity: 0.8;
    }
  }
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image {
  aspect-ratio: 164/291;
}

.playIcon,
.pauseIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: $lights-high;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.pauseIcon {
  display: none;
}
