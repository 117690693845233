$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  display: inline-block;
  padding: 4px;

  .button {
    padding: 4px;
  }
}

.tooltipRoot {
  background-color: $background-default;
  color: $lights-high;
  border: 1px solid $border-default;
  opacity: 1;
  z-index: 1;
}

.tooltipArrow {
  border-bottom: 1px solid $border-default;
  border-right: 1px solid $border-default;
  opacity: 1;
  z-index: 1;
}

.rightTooltipArrow {
  border-right: none;
  border-left: 1px solid $border-default;
}

.content {
  display: flex;
  flex-direction: column;

  .inputWrapper {
    display: inline-flex;
    padding-bottom: 8px;

    .input {
      padding-right: 8px;

      & input {
        padding: 6px;
      }

      &.isInvalid {
        input {
          border-color: $error-default;
        }
      }
    }

    .button {
      padding: 4px;
    }
  }
}

.title {
  padding: 6px 0 10px;

  @include text-body1Regular16;
}

.icon {
  color: #1890ff;

  &.isActive {
    color: white;
  }
}

.checkbox {
  padding-bottom: 8px;
}
