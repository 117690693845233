$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

$checkbox-size: 20px;

.checkBoxContainer {
  margin: 0;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.checkBoxLabel {
  position: relative;
  padding-left: $checkbox-size + 10px;
  height: $checkbox-size;
  color: $mine-shaft;
  letter-spacing: -0.2px;
  cursor: pointer;

  @include font_size(12px);
  @include line_height($checkbox-size);

  &.bold {
    font-weight: $semi_bold_font_weight;

    @include font_size(15px);
    @include line_height(20px);
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: $checkbox-size;
    height: $checkbox-size;
    box-sizing: border-box;
  }

  &:before {
    content: "";
    border: $border-secondary;
    background: transparent;
    &:checked {
      background: $harvest-gold;
    }
  }

  &:after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.75 0C8.405 0 8.0925 0.14 7.86625 0.36625L6.61625 1.61625L3.75 4.4825L2.13375 2.86625C1.9075 2.64 1.595 2.5 1.25 2.5C0.56 2.5 0 3.06 0 3.75C0 4.095 0.14 4.4075 0.36625 4.63375L2.86625 7.13375C3.0925 7.36 3.405 7.5 3.75 7.5C4.095 7.5 4.4075 7.36 4.63375 7.13375L8.38375 3.38375L9.63375 2.13375C9.86 1.9075 10 1.595 10 1.25C10 0.559375 9.44 0 8.75 0Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $harvest-gold;
    background-color: $harvest-gold;
    @include font_size(10px);
  }
}

.checkBoxInput {
  position: absolute;
  left: -9999px;

  + label {
    &:after {
      opacity: 0;
      transform: scale(0);
    }
  }

  &:hover,
  &:active {
    + label {
      &:before,
      &:after {
        transform: scale(1);
        opacity: 0.25;
      }
    }
  }

  &:checked {
    + label {
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }

    &:hover {
      + label {
        &:before,
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &:disabled {
    + label {
      user-select: none;
      pointer-events: none;
      cursor: not-allowed;

      &:before,
      &:after {
        border-color: $concrete;
        background-color: $concrete;
      }
    }

    &:checked {
      + label {
        &:before,
        &:after {
          border-color: $alto-light;
          background-color: $alto-light;
          color: $dusty-grey;
        }
      }
    }
  }
}
