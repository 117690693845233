$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

$switch-width: 50px;
$switch-height: 28px;
$switch-circle-indent: 2px;

.root {
  margin: 0;
  height: $switch-height;
}

.checkbox {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;

  &:checked + .switchWrapper {
    &:before {
      background: $accent-default;
    }

    &:after {
      left: $switch-width - $switch-circle-indent;
      background: $lights-high;
      transform: translateX(-100%);
    }
  }

  &[disabled] + .switchWrapper {
    &,
    &:hover,
    &:focus,
    &:active {
      &,
      &:before,
      &:after {
        user-select: none;
        pointer-events: none;
        cursor: not-allowed;
        filter: grayscale(0.75);
      }
    }
  }
}

.switchWrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: $switch-width;
  height: $switch-height;
  cursor: pointer;

  color: $lights-high;
  @include text-body2Regular;

  &.withText {
    padding-left: $switch-width + 8px;
  }

  &.bold {
    @include text-body2Medium;
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: $switch-width;
    height: $switch-height;
    background: $lights-dim;
    border-radius: 18px;
    cursor: pointer;
  }

  &:after {
    content: "";
    position: absolute;
    top: $switch-circle-indent;
    left: $switch-circle-indent;
    width: $switch-height - $switch-circle-indent * 2;
    height: $switch-height - $switch-circle-indent * 2;
    background: $lights-high;
    border-radius: 100%;
    transition: 0.15s;
  }

  &:active {
    &:before {
      filter: grayscale(0.5);
    }

    &:after {
      width: $switch-height * 1.125;
      filter: grayscale(0.5);
    }
  }
}
