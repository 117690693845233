$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: flex;
  padding: $spacing-1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: $spacing-0;
  margin-bottom: $spacing-1;
}

.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2; /* Limits to 2 lines */
  -webkit-box-orient: vertical;
}

// Generate line clamp classes from 1 to 4
@for $i from 1 through 4 {
  .lineClamp#{$i} {
    -webkit-line-clamp: $i;
    line-clamp: $i;
  }
}

// Small, won't be used on mobile, so medium will be default
// .title {
//   @include text-subtitle3;
// }
// .user,
// .date {
//   @include text-body3Regular;
// }

// Medium
.title {
  @include text-subtitle2;
}
.user {
  @include text-body2Regular;
}
.date {
  @include text-body3Regular;
}

@media screen and (min-width: $breakpoint_ml) {
  // Large
  .root {
    gap: $spacing-1;
  }
  .title {
    @include text-subtitle1;
  }
  .user {
    @include text-body1Regular16;
  }
  .date {
    @include text-body2Regular;
  }
}
