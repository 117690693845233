$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-3;
  color: $lights-high;
}

.posterImage {
  width: 280px;
  border-radius: $radius-2;
  overflow: hidden;
}
