$cdn: 'https://cdn.millions.co';
@import "styles/scss/_base";

.root {
  width: 100%;
  height: 100%;
  position: relative;

  > span {
    position: static !important;
    height: 100% !important;
  }
}

.image {
  width: 100% !important;
  position: relative !important;
  height: auto !important;
}
