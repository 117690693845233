$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  position: relative;
}

.button {
  @extend %button-reset;

  cursor: pointer;
  border: $border-0 solid $border-default;
  border-radius: 100%;
  overflow: hidden;
  color: $lights-high;

  &:hover {
    border-color: $border-bright;
  }

  .image {
    width: 32px;
    height: 32px;
    object-fit: contain;
    object-position: center;
  }
}

.dropdown {
  display: none;

  &.visible {
    display: block;
    position: absolute;
    top: 48px;
    right: 0;
    background-color: $background-dim;
    border: $border-1 solid $border-bright;
    border-radius: $radius-1;
    z-index: 1;
    overflow: hidden;
    padding: $spacing-3;
  }

  .option {
    min-width: 240px;
    cursor: pointer;
    padding: $spacing-2 $spacing-3;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacing-1;
    padding: $spacing-2 $spacing-3;

    &:hover {
      background-color: $background-light;
    }
  }

  .divider {
    height: 1px;
    background-color: $border-default;
    margin: $spacing-3 0;
  }

  .logoutButton {
    @extend %button-reset;

    width: 100%;
    color: $lights-high;
  }
}
