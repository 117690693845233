$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.leftSection {
  display: flex;
  flex-direction: column;
  gap: $spacing-5;
  padding: $spacing-6 0;
}

.rightSection {
  padding: $spacing-6 0;
}
