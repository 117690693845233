$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $spacing-1;
}

.label {
  @include text-body2Medium;
}

.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 2;

  input {
    // doesn't work without !important
    color: $lights-high !important;
  }

  [class*="control"] {
    width: 100%;
    border: $border-1 solid $border-default;
    border-radius: $radius-1;
    background-color: $background-default;
    word-wrap: break-word;
    min-height: 56px;
    padding: 0px 16px;
    box-shadow: none;

    @include text-body1Regular16;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
      border: $border-1 solid $border-default;
    }
  }

  [class*="menu"],
  [class*="MenuList"] {
    color: $lights-high;
    border: $border-1 solid $border-default;
    border-radius: $radius-1;
    word-wrap: break-word;
    align-items: center;
    margin: 0;
    padding: 0;
    box-shadow: none;
    overflow: hidden;

    @include text-body1Regular16;
    text-transform: uppercase;
  }

  [class*="option"] {
    background-color: $background-default;
    &:hover,
    :active,
    :focus,
    :default {
      border: none;
      background-color: $background-light;
    }
  }

  [class*="NoOptionsMessage"] {
    color: $lights-high;
    background-color: $background-default;
    text-transform: none;
  }

  [class*="multiValue"] > div:first-child {
    border-radius: $radius-0;
    background-color: $lights-high;
    color: $background-dim;
    @include text-smallLabelBold;
  }

  [class*="multiValue"] > div:nth-child(2) {
    border-radius: $radius-0;
    background-color: $lights-high;
    color: $background-dim;
    @include text-smallLabelBold;
  }

  [class*="multiValue"] > div:nth-child(2) > svg {
    cursor: pointer;
  }

  [class*="placeholder"] {
    text-transform: none;
  }
}
