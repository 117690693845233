$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  cursor: pointer;
  display: flex;
  align-items: center;

  @extend %button-reset;

  &:hover,
  &:focus {
    .cartIcon {
      color: $lights-dim;
    }
  }

  .cartIcon {
    color: $lights-high;
  }

  .cartCounter {
    color: $background-dim;
    background-color: $lights-high;
  }
}

.cartIcon {
  margin-right: 2px;
}

.cartCounter {
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  flex-shrink: 0;

  @include text-smallLabelBold;
  @include flexCenter;
}
