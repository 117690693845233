$cdn: 'https://cdn.millions.co';
$gold: #d2ab64;

.root {
  .button {
    padding: 4px;
  }
}

.isActive {
  background-color: $gold;
  border-color: $gold;
}

.icon {
  svg {
    color: $gold;
  }

  &.isActive {
    svg {
      color: white;
    }
  }
}
