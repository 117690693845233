$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

$circle_size-small: 20px;
$circle_size-medium: 20px;
$circle_size-large: 24px;

.inputContainerWrapper {
  @extend %inputContainerWrapper;
}

.inputContainer {
  @extend %inputContainer;
}

.inputWrapper {
  overflow: hidden;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0;
  background: $white;
  border: none;

  &.disabled {
    background: $concrete;
    & > * {
      cursor: not-allowed;
      user-select: none;
      pointer-events: none;
    }
  }

  &:not(.disabled):hover {
  }

  &:focus-within {
  }

  .readyIcon {
    margin: auto 4px;
    color: $chateau-green;
  }

  &.size {
    &-large {
      .readyIcon {
        @include font_size($circle_size-large);
      }

      .passwordToggle {
        @include font_size($circle_size-large);
      }

      .actionWrapper {
        margin-right: -12px;
      }
    }

    &-medium {
      .readyIcon {
        @include font_size($circle_size-medium);
      }

      .passwordToggle {
        @include font_size($circle_size-medium);
      }

      .actionWrapper {
        margin-right: -8px;
      }
    }

    &-small {
      .readyIcon {
        @include font_size($circle_size-small);
      }

      .passwordToggle {
        @include font_size($circle_size-small);
      }

      .actionWrapper {
        margin-right: -8px;
      }
    }
  }
}

.input {
  @extend %input;

  &.withPrefix {
    &.size {
      &-large {
        padding-left: $input-container-x-padding-large + 6px;
      }

      &-medium {
        padding-left: $input-container-x-padding-medium + 10px;
      }

      &-small {
        padding-left: $input-container-x-padding-small + 10px;
      }
    }
  }

  &.withDotsInPlaceholder {
    &,
    &[disabled],
    &[readonly] {
      &,
      &:hover,
      &:focus,
      &:active {
        &::placeholder {
          color: $white;
          -webkit-text-fill-color: $white;
          opacity: 0.4;

          @include font_size(40px);

          height: inherit;
          line-height: inherit;
        }
      }
    }
  }
}

.endAdornment {
  background-color: $white;
  display: flex;
  align-items: center;
}

.passwordToggle {
  @extend %button-reset;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  height: 100%;
  padding: 17px;
  color: $black;
  border: 1px solid $light-gray;
  border-left: none;
  margin-left: -1px;

  @media (max-width: $breakpoint_s) {
    padding: 18.5px;
  }

  .passwordToggleIcon {
    color: inherit;
    font-size: inherit;
  }
}

.errorHint {
  @extend %errorHint;
}

.label {
  position: relative;

  @extend %label;
}

/*
* !important here is not a kludge. it's a common way to style the react-tooltip
* https://www.npmjs.com/package/react-tooltip
*/
.tooltip {
  padding: 8px 10px !important;
  background-color: $white !important;
  max-width: 210px !important;
  box-shadow: 0 4px 8px rgba(26, 26, 26, 0.2) !important;
  opacity: 1 !important;
  z-index: 1;
}

.tooltipIcon {
  margin-left: 5px;
  color: $harvest-gold;
}

.actionWrapper {
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
  align-self: stretch;
}

.action {
  padding: 0 24px;

  &,
  &:hover {
    box-shadow: none;
  }
}

.inputPrefix {
  position: absolute;
  margin: 15px 0 14px 20px;
  font-family: inherit;
  outline: none;

  @include text-sm-regular;

  @at-root .size {
    &-large {
      .inputPrefix {
        margin: 15px 0 14px $input-container-x-padding-large;
      }
    }

    &-medium {
      .inputPrefix {
        margin: 15px 0 14px $input-container-x-padding-medium;
      }
    }

    &-small {
      .inputPrefix {
        margin: auto 0 auto $input-container-x-padding-small;
      }
    }
  }

  &.error {
    color: $maximum-red;
  }

  &.disabled {
    color: rgba($white, 0.4);
  }
}

.notice {
  text-align: left;

  &.size {
    &-large {
      margin: 8px 0 0;
    }

    &-medium {
      margin: 8px 0 0;
    }

    &-small {
      margin: 8px 0 0;
    }
  }
}

.topNotice {
  position: absolute;
  right: 0;
  bottom: 0;
}
