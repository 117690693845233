$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $spacing-4;
}

.label {
  @include text-h6;

  text-align: center;
  text-transform: capitalize;
}

.input {
  width: 100%;
  padding: $spacing-4;
  border: $border-1 solid $accent-default;
  border-radius: $radius-2;
  outline: none;
  font-family: inherit;
  appearance: none;
  background-color: $background-default;

  @include text-h5;

  color: $lights-high;
  text-align: center;
  text-transform: uppercase;

  &::placeholder {
    color: $lights-dim;
  }

  &.error {
    border-color: $error-default;
  }

  &.valid {
    border-color: $success-default;
  }
}

.errorLabel {
  display: block;
  width: 100%;
  max-width: 286px;
  color: $error-default;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  font-size: 12px;
  text-align: center;
  padding: 0 $spacing-4;
}
