$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";
@import "styles3/scss/base";

.root {
  width: 100%;
  display: flex;
  flex-direction: column;

  .input,
  div[data-slate-editor="true"] {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
  }

  div[data-slate-editor="true"] {
    min-height: 120px;
  }

  .entry {
    border: $border-1 solid $border-default;
    border-radius: $radius-1;
    background-color: $background-default;

    &:focus-within {
      border-color: $border-bright;
    }
  }

  &.error {
    .entry {
      border-color: $error-default;
    }
  }

  .label {
    margin-bottom: 7px;
    color: $lights-high;
  }

  &.disabled {
    .entry {
      background: $border-black;
      border-color: $border-black;
    }

    .input,
    div[data-slate-editor="true"],
    .label,
    .actionIcon {
      color: $lights-low;
    }
  }

  .input,
  div[data-slate-editor="true"] {
    padding: 0;
    color: $lights-high;
    border-color: $border-default;

    &::placeholder {
      color: $lights-low;
      -webkit-text-fill-color: $lights-low;
    }
  }

  .actionIcon {
    color: $lights-high;
  }

  .noticeLabel {
    color: $lights-high;
  }

  &.size {
    &-default {
      .entry {
        padding: 10px 8px;
      }

      &.unlabeled {
        .entry {
          padding: 17px 24px;
        }

        .input,
        div[data-slate-editor="true"] {
          border: none;
        }
      }

      &.withAction {
        .entry {
          padding-right: 60px;
        }
      }

      .input,
      div[data-slate-editor="true"] {
        @include text-lg-medium;
      }
    }

    &-small {
      .entry {
        padding: 10px 8px;
      }

      &.unlabeled {
        .entry {
          padding: 0;
        }

        .input {
          border: none;
          padding: 10px 8px 11px;
        }

        &.withAction {
          .input {
            padding-right: 44px;
          }
        }
      }

      &.withAction {
        .actionButton {
          right: 8px;
        }
      }

      .input {
        font-family: "GT America";
        font-weight: $regular_font_weight;
        letter-spacing: 0.02em;

        @include font-size(14px);
        @include line-height(17px);
      }
    }
  }

  &.multiline {
    .input {
      resize: none;
    }
  }

  &.disabled {
    &,
    * {
      cursor: no-drop;
    }
  }
}

.input {
  border-radius: 0;
  margin: 0;
  appearance: none;
}

.label {
  display: block;

  @include text-xs-medium-uppercase;
}

.textFieldWrapper,
.entry {
  position: relative;
  display: flex;
  flex-direction: column;
  &.prefix {
    justify-content: center;
    & input {
      text-indent: 32px;
    }
  }
}

.errorLabel {
  display: block;
  margin-top: 8px;
  color: $error-default;

  @include text-sm-regular;
}

.actionButton {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translate(0, -50%);
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;

  &:not(:disabled):hover {
    opacity: 0.8;
  }

  @include flexCenter;
}

.actionIcon {
  font-size: 24px;
}

.noticeLabel {
  display: block;
  margin-top: 8px;

  @include text-sm-regular;
}

.endAdornment {
  display: flex;
  align-items: center;
  margin: auto 4px;
}

.passwordToggle {
  @extend %button-reset;

  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding: 8px;
  color: $lights-high;

  .passwordToggleIcon {
    color: inherit;
    font-size: inherit;
  }
}

.prefixIcon {
  position: absolute;
  color: $lights-high;
}
