$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  margin: $spacing-2 $spacing-3;
  padding: $spacing-4;
  border: $border-1 solid $accent-default;
  border-radius: $radius-2;
  background: linear-gradient(
    to bottom right,
    $background-default 65%,
    mix($background-default, $gradient-bright, 80%) 75%,
    mix($background-default, $gradient-dim, 50%) 85%,
    $gradient-default 95%
  );

  @include grain-overlay(0.35);
}

.actions {
  display: flex;
  justify-content: flex-start;
  
  .startSpanButton {
    padding-left: 0;
  }

  @media screen and (min-width: $breakpoint_l) {
    justify-content: flex-start;
  }
}
