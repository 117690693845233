$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  display: inline-block;
  position: relative;
}

.button {
  @extend %button-reset;
  @include text-body1Medium16;
  color: $lights-high;

  display: inline-flex;
  align-items: center;
}

.optionsList {
  display: none;

  &.visible {
    display: block;
    position: absolute;
    top: 28px;
    left: 0;
    background-color: $background-dim;
    border: $border-1 solid $border-bright;
    border-radius: $radius-1;
    z-index: 1;
    overflow: hidden;
  }

  .option {
    cursor: pointer;
    padding: $spacing-2 $spacing-3;
    white-space: nowrap;

    &:hover {
      background-color: $background-light;
    }

    a,
    button {
      white-space: nowrap;
      display: inline-block;
    }
  }
}

.icon {
  color: $lights-high;
}
