$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  padding-bottom: 15px;
}

.editorWrapper {
  color: $black;
  border: 1px solid $light-gray;
  border-radius: 32px;
  font-family: "GT America";

  &:hover,
  &:focus-within {
    border-color: $gainsboro;
  }
}

.editor {
  padding: 18px 24px;

  p {
    span {
      span {
        display: block;
      }
    }
  }
}

.errorHint {
  text-align: left;
  margin: 8px 8px 0 24px;
  color: $maximum-red;
  font-style: normal;
  @include text-sm-regular;
}

.label {
  color: $black;
  font-size: 12px;
  font-family: GT America;
  letter-spacing: 0.24px;
}
