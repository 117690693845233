$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  padding-bottom: 15px;
}

.editorWrapper {
  color: $lights-high;
  border: $border-1 solid $border-default;
  border-radius: $radius-1;
  background-color: $background-default;

  @include text-body1Regular16;

  &::placeholder {
    color: $lights-low;
  }

  &:hover,
  &:focus-within {
    border-color: $border-bright;
  }
}

.editor {
  padding: 18px 24px;

  p {
    span {
      span {
        display: block;
      }
    }
  }
}

.label {
  @include text-body2Medium;
}
