$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  border-top: $border-1 solid $border-default;
  padding-top: $spacing-4;
}

.otherMentionsLabel {
  color: $lights-high;
  word-wrap: break-word;
}

.addNewButton {
  padding: 0 !important;
}

.athletesSelectContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-2;
}
