$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.tabList {
  padding: 0 16px 24px;
  display: flex;
  overflow: scroll;

  @media screen and (min-width: $breakpoint_m) {
    display: inline-flex;
    overflow: hidden;
  }
}

.underline {
  li {
    color: $lights-dim;
    font-weight: 500;
    font-family: "GT America";

    @include font_size(14px);
    @include line_height(20px);
  }
}

.segmented {
  padding: $spacing-1;
  background: $background-dim;
  border: $border-1 solid $border-default;
  border-radius: $radius-4;
}
