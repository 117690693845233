$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.socialIcons {
  display: flex;
  align-items: center;

  .socialIconWrapper {
    &:not(:last-child) {
      margin: 0 16px 0 0;
    }
  }

  .socialIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    &:hover,
    &:focus {
      color: $hover-color;
    }

    &:active {
      color: $hover-color;
    }
  }
}
