$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  display: flex;
  gap: $spacing-2;
  width: 100%;
}

.storesSelect {
  label {
    color: $lights-high !important;
    letter-spacing: 0.24px;
    word-wrap: break-word;
    text-transform: none;
  }

  [class*="placeholder"] {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-transform: none;
  }

  [class*="control"] {
    width: 100%;
    border: none;

    &:hover,
    :active,
    :focus {
      border: none;
    }
  }

  [class*="menu"] {
    border-radius: 0px;
    word-wrap: break-word;
    align-items: center;
    margin-top: 0px;

    & div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  [class*="option"] {
    &:hover,
    :active,
    :focus,
    :default {
      border: none;
      background-color: $accent-default;
    }
  }
}

.singleValue,
.optionsValue {
  display: flex;
  align-items: center;
  text-transform: none;
  word-wrap: break-word;
}

.deleteButton {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: $accent-default;
}

.imageAvatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}
