$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: $spacing-4;
  background: $background-default;
  border: $border-1 solid $border-default;
  border-radius: $radius-2;

  &.isSelected {
    border-color: $accent-default;
  }
}
