$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  position: relative;
  top: 0;
  width: 100%;
  height: $header_height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1001;
  transition: background-color 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  color: $lights-high;

  background-color: $background-default;
  border-bottom: $border-1 solid $border-default;

  @media screen and (min-width: $breakpoint_l) {
    display: none;
  }

  &.transparent {
    background-color: transparentize($lights-high, 0.5);
  }

  &.fixed {
    position: fixed;
  }

  .logoWrapper {
    border-right: $border-default;
  }

  .menuToggle {
    span {
      background: $lights-high;
    }
  }

  .nav {
    background-color: $background-default;
  }

  .authButton:first-child {
    border-top: $border-default;
    border-bottom: $border-1 solid $border-bright;
  }

  .logoutButton:first-of-type {
    border-top: $border-default;
    border-bottom: $border-1 solid $border-bright;
  }

  .link {
    border-bottom: $border-1 solid $border-bright;
  }

  .collapseWrapper {
    background-color: $background-default;

    & div > button > i {
      color: $lights-high;
    }

    &:not(:last-of-type) {
      border-bottom: $border-1 solid $border-bright;
    }

    &.lastCollapse {
      border-bottom: 0;
    }
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  height: 100%;
  padding: $spacing-4;

  .logo {
    width: 29px;
    height: auto;
    cursor: pointer;
  }
}

.menuWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-4;
}

.rightControls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.upgradeButton {
  white-space: nowrap;
}

.menuToggle {
  -webkit-user-select: none;
  user-select: none;
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;

  &:hover {
    color: $lights-dim;
  }

  span {
    display: block;
    width: 19px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    &:nth-of-type(3) {
      margin-bottom: 0;
    }
  }

  &.open {
    span {
      &:nth-of-type(1) {
        opacity: 1;
        transform: rotate(45deg) translate(2px, 3px);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        opacity: 1;
        transform: rotate(-45deg);
        transform-origin: 0 100%;
      }
    }
  }
}

.nav {
  position: absolute;
  overflow: auto;
  top: $header_height;
  height: calc(100vh - #{$header_height});
  width: 100%;
  flex-direction: column;
  padding-bottom: 24px;
  list-style-type: none;
  display: none;

  &.open {
    display: flex;
  }
}

.subMenu {
  padding: $spacing-4 0 0;
}

.link {
  padding: 24px $spacing-4;
  display: block;
}

.link,
.parentMenuLink {
  @include text-h5;

  text-transform: uppercase;

  &.active {
    color: $accent-default;
  }

  @at-root button#{&} {
    @extend %button-reset;
  }
}

.childMenuLink {
  display: block;
  border: none;
  background-color: transparent;
  padding: 0;
  color: $lights-high;

  @include text-smallLabelBold;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &.active {
    color: $accent-default;
  }
}

.collapseWrapper {
  overflow: visible;
  border: none;
  border-radius: 0;
  padding: 24px $spacing-4;
  display: block;

  button {
    svg {
      color: $lights-high;
    }
  }
}

.authButtonWrapper {
  width: 100%;
  margin-bottom: 26px;

  .authButton {
    width: 50%;
  }
}

.logoutButton:first-of-type {
  margin-bottom: 26px;
}

.socialIconWrapper {
  padding-left: $spacing-4;
  margin-bottom: 52px;
}

.externalLinkWrapper {
  padding-left: $spacing-4;
  display: flex;

  .externalLink {
    @include text-smallLabelRegular;

    &:first-child {
      margin-right: 24px;
    }
  }
}

.inverted {
  background: $background-default;
  color: $lights-high;
}
