$cdn: 'https://cdn.millions.co';
@import "styles/scss/base";

.root {
  position: relative;
  min-width: 200px;
  min-height: 100px;
}

.container {
  background-color: inherit;
}
