$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  position: relative;
  width: 100%;
  background-color: $background-dim;
  z-index: 6;
  padding: $spacing-3 $spacing-4;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: $header_height;
  border-bottom: $border-1 solid $border-default;
  z-index: 1001;

  @media screen and (min-width: $breakpoint_l) {
    display: flex;
    flex-direction: row;
    gap: $spacing-2;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    padding: $spacing-3 $spacing-6;
  }

  &.fixed {
    position: fixed;
    top: 0px;
  }
}

.leftPart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: $spacing-5;

  .logoWrapper {
    height: 100%;
    padding: 0 32px 0 0;
    display: flex;
    align-items: center;
    position: relative;

    .logo {
      width: 160px;
      height: auto;
      cursor: pointer;
    }
  }

  .linksWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $spacing-4;

    .link {
      @include text-body1Medium16;
    }
  }

  .searchWrapper {
    min-width: 380px;
  }
}

.shortLeftPart {
  gap: 0;

  .logoWrapper {
    padding: 0 12px 0 0;

    .logo {
      max-width: 135px;
    }
  }

  .linksWrapper {
    gap: 8px;
  }

  .searchWrapper {
    min-width: 335px;
  }
}

.rightPart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: $spacing-2;
}
