$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  color: $lights-high;
  margin-bottom: 20px;
  min-height: 450px;
  max-width: 400px;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 0;
    min-height: 420px;
  }
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
}

.daysCheckbox {
  display: flex;
  flex-direction: row;
  gap: 13px;
  margin: 20px 0 0;

  @media screen and (min-width: $breakpoint_l) {
    margin: 10px 0 30px;
  }

  .checkbox {
    cursor: pointer;
    background-color: $background-light;
    color: $lights-low;
    border: none;
    width: 28px;
    height: 28px;
    border-radius: 100px;
  }

  .checkedCheckbox {
    background-color: $lights-dim;
    color: $lights-high;
  }
}

.day {
  display: flex;
  flex-direction: column;
}

.timeRangeWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  text-align: left;

  @media screen and (min-width: $breakpoint_l) {
    flex-direction: row;
    text-align: left;
    gap: 10px;
    margin-bottom: 12px;
  }

  .dayName {
    width: 100%;
    color: $lights-high;
    font-weight: 700;
    margin-top: 15px;

    @media screen and (min-width: $breakpoint_l) {
      width: 120px;
      font-weight: 400;
      margin-top: 0px;
    }
  }

  .timeRange {
    display: flex;
    flex-direction: row;
    width: 350px;

    .timeInput {
      flex-grow: 0;
      align-self: start;
    }

    .timeSeparator {
      padding: 20px 10px;
      color: $lights-high;
      align-self: start;
    }
  }
}

.iconWrapper {
  display: flex;
  gap: 10px;
}

.icon {
  cursor: pointer;
  background-color: $background-default;
  color: $accent-default;
  height: 22px;
  width: 22px;

  @media screen and (min-width: $breakpoint_l) {
    height: 18px;
    width: 18px;
  }
}
