%card {
  width: 100%;
  background: $white;
  border: 1px solid $alto-light;
  border-radius: 6px;
  overflow: hidden;
}

%input {
  margin: 0;
  padding: 14px 20px;
  width: 100%;
  border-radius: 32px;
  border: $border-secondary;
  background-color: $white;
  box-shadow: none;
  color: $black;
  font-family: inherit;
  outline: none;

  @include font_size(16px);
  @include line_height(18px);

  @media screen and (min-width: $breakpoint_s) {
    @include font_size(14px);
  }

  &:hover,
  &:focus {
    border-color: $black;
  }

  &::placeholder {
    color: $dusty-grey;
  }

  &.error {
    border-color: $rose-red;
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      background-color: $concrete;
      border-color: $concrete;
      color: $alto-light;
      user-select: none;
      pointer-events: none;
      cursor: not-allowed;

      &::placeholder {
        color: $alto-light;
      }
    }
  }
}

%label {
  padding: 0;
  display: block;
  color: $mine-shaft;
  letter-spacing: -0.2px;
  margin: 0 0 8px 20px;

  @include font_size(12px);
  @include line_height(18px);

  &.size {
    &-medium {
      margin: 0 20px 8px;

      @include font_size(12px);
      @include line_height(18px);
    }

    &-large {
      margin: 0 24px 8px;

      @include font_size(14px);
      @include line_height(20px);
    }
  }
}

%button-reset {
  border: none;
  background: none;
  cursor: pointer;

  &,
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  &[disabled],
  &[disabled] > * {
    cursor: not-allowed;
    user-select: none;
    pointer-events: none;
  }
}

%button {
  position: relative;
  border: none;
  border-radius: 32px;
  text-transform: uppercase;
  font-weight: $semi_bold_font_weight;
  transition: box-shadow 0.15s ease-in-out;
  font-family: "Montserrat", sans-serif;

  &:hover {
    box-shadow: 0 7px 8px rgba(0, 0, 0, 0.05), 0 5px 22px rgba(0, 0, 0, 0.03),
      0 12px 17px rgba(0, 0, 0, 0.04);
    transition: box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }

  @each $name, $value in $interface_colors {
    &.color-#{$name} {
      background-color: $value;
      border: 1px solid $value;

      @if $name == "harvest-gold" {
        color: $black;
      } @else if $name == "chateau-green" {
        color: $black;
      } @else if $name == "transparent" {
        color: $black;
        border-color: $dusty-grey;
      } @else if $name == "transparent-gold" {
        color: $harvest-gold;
        border-color: $harvest-gold;
        background: transparent;
      } @else if $name == "black-gold" {
        color: $harvest-gold;
        border: 1px solid $harvest-gold;
      } @else {
        color: $white;
      }

      .buttonIcon {
        @if $name == "harvest-gold" {
          color: $black;
        } @else if $name == "black-gold" {
          color: $harvest-gold;
        } @else if $name == "black" {
          color: $harvest-gold;
        } @else if $name == "transparent" {
          color: inherit;
        } @else if $name == "transparent-gold" {
          color: inherit;
        } @else {
          color: $white;
        }
      }

      &:focus,
      &:active {
        background-color: darken($value, 6.2);
        outline: none;
      }
    }
  }

  &.size {
    &-xxSmall {
      padding: 4px 10px;

      @include font_size(10px);
      @include line_height(16px);

      .buttonIcon {
        @include font_size(12.8px);
      }

      &.withEndIcon {
        .buttonIcon {
          margin-left: 4.59px;
        }
      }

      &.withStartIcon {
        .buttonIcon {
          margin-right: 4.59px;
        }
      }
    }

    &-small,
    &-xSmall {
      @include font_size(10px);
      @include line_height(14px);

      .buttonIcon {
        @include font_size(12.8px);
      }

      &.withEndIcon {
        .buttonIcon {
          margin-left: 4.59px;
        }
      }

      &.withStartIcon {
        .buttonIcon {
          margin-right: 4.59px;
        }
      }
    }

    &-xSmall {
      padding: 8px 20px;
    }

    &-small {
      padding: 11px 20px;
    }

    &-medium {
      padding: 14px 24px;

      @include font_size(12px);
      @include line_height(18px);

      .buttonIcon {
        @include font_size(14.6px);
      }

      &.withEndIcon {
        .buttonIcon {
          margin-left: 8.68px;
        }
      }

      &.withStartIcon {
        .buttonIcon {
          margin-right: 8.68px;
        }
      }
    }

    &-large {
      padding: 17px 32px;

      @include font_size(14px);
      @include line_height(20px);

      .buttonIcon {
        @include font_size(18.3px);
      }

      &.withEndIcon {
        .buttonIcon {
          margin-left: 8.85px;
        }
      }

      &.withStartIcon {
        .buttonIcon {
          margin-right: 8.85px;
        }
      }
    }

    &-xLarge {
      padding: 19px 40px;
      font-weight: $semi_bold_font_weight;
      border-radius: 40px;

      @include font_size(16px);
      @include line_height(24px);

      .buttonIcon {
        @include font_size(22px);
      }

      &.withEndIcon {
        .buttonIcon {
          margin-left: 9px;
        }
      }

      &.withStartIcon {
        .buttonIcon {
          margin-right: 9px;
        }
      }
    }
  }

  &.loading {
    justify-content: center;
    color: transparent;

    .buttonIcon {
      color: transparent;
    }

    .buttonContent {
      border-color: transparent;
      background: transparent;
      color: transparent;
    }
  }

  &.disabled,
  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      opacity: 0.8;
      cursor: not-allowed;
      user-select: none;
      box-shadow: none;

      &:not(.loading) {
        filter: grayscale(1);
      }

      > * {
        user-select: none;
        pointer-events: none;
      }
    }
  }

  input[type="file"] {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;

    &::-webkit-file-upload-button,
    &:hover {
      cursor: pointer;
    }
  }

  .buttonContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: max-content;
  }

  &.withEndIcon,
  &.withStartIcon {
    .buttonContent {
      justify-content: space-between;
    }
  }
}

%paper-card {
  background: $white;
  border: 1px solid $concrete;
  box-sizing: border-box;
  box-shadow: 0 11px 15px rgba(0, 0, 0, 0.05), 0 9px 46px rgba(0, 0, 0, 0.03),
    0 24px 38px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
